import React from "react";

function Resume(props) {
  const {
    year,
    position,
    graduation,
    university,
    company,
    details,
    company_url,
  } = props.resumeData;
  return (
    <div className="mi-resume mt-30">
      <div className="mi-resume-summary">
        <h6 className="mi-resume-year">{year}</h6>
      </div>
      <div className="mi-resume-details">
        <h5>{position || graduation}</h5>
        <h6 className="mi-resume-company">
          {company ? <a href={company_url}>{company}</a> : university}
        </h6>
        <p className="mi-resume-text">{details}</p>
      </div>
    </div>
  );
}

export default Resume;
