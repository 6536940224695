import Mock from "../mock";

const database = {
  information: {
    name: "James",
    fullname: "Ikwechegh James",
    aboutContent:
      "I'm a passionate and highly effective software developer who has successfully developed applications for mobile and the web. Well-educated and poised with more than 6 years of expertise",
    // age: 26,
    phone: "+46768649598",
    nationality: "Nigerian",
    language: "English",
    email: "admin@jaycodes.com",
    address: "Stockholm, Sweden",
    freelanceStatus: "Available",
    socialLinks: {
      facebook: "https://facebook.com/ikwechegh",
      twitter: "https://twitter.com/jaycodes_",
      pinterest: "",
      behance: "",
      linkedin: "https://www.linkedin.com/in/ikwechegh/",
      dribbble: "",
      github: "https://github.com/jay102",
    },
    brandImage:
      "https://res.cloudinary.com/dzgishswf/image/upload/v1626111201/portfolio/jay_uwf5yz.jpg",
    aboutImage:
      "https://res.cloudinary.com/dzgishswf/image/upload/v1626111201/portfolio/jay_uwf5yz.jpg",
    aboutImageLg:
      "https://res.cloudinary.com/dzgishswf/image/upload/v1626111201/portfolio/jay_uwf5yz.jpg",
    cvfile:
      "https://drive.google.com/file/d/1mB2bmgQa9e4Uzuh73J1jx1UPVgG0kI0_/view?usp=sharing",
  },
  services: [
    {
      title: "UI Conversion",
      icon: "code",
      details:
        "I convert Figma/Adobexd/Invision/Photoshop UI's to pixel perfect React Websites",
    },
    {
      title: "Frontend Development",
      icon: "code",
      details:
        "I develop World-class web application solutions using Javascript and ReactJS",
    },
    {
      title: "Backend Development",
      icon: "code",
      details:
        "I develop fast,scalable and maintenable backend services and API's using Node",
    },
  ],
  reviews: [
    {
      id: 1,
      content:
        "My go-to front end dev now, thanks for another delivery ahead of schedule!",
      author: {
        name: "Alex",
        designation: "Fiverr Client",
      },
    },
    {
      id: 2,
      content:
        "Absolutely a must, if you want your app designed, Jay is the one for you - patient, has interesting input, great personality and very articulate which helps when designing something. This has been a superb journey which I am grateful for. Excellent seller!",
      author: {
        name: "Sacha",
        designation: "Fiverr Client",
      },
    },
    {
      id: 3,
      content:
        "Amazing! Not only helped, but explained the code thoroughly. Would highly recommend.",
      author: {
        name: "usamahscript",
        designation: "Fiverr Client",
      },
    },
    {
      id: 4,
      content:
        "Excellent work. The quality of his work is excellent. Outstanding communication, exceptional professionalism, and the final product is just “WOW!”",
      author: {
        name: "Vishnu",
        designation: "Fiverr Client",
      },
    },
  ],
  skills: [
    {
      title: "HTML",
      value: 95,
    },
    {
      title: "CSS(Sass)",
      value: 90,
    },
    {
      title: "Javascript",
      value: 80,
    },
    {
      title: "Node",
      value: 85,
    },
    {
      title: "ReactJS",
      value: 85,
    },
    {
      title: "Express",
      value: 75,
    },
  ],
  portfolios: [
    {
      id: 1,
      title: "MyCookbookIo",
      subtitle: "Implementation of MyCookbook UI",
      imageUrl:
        "https://res.cloudinary.com/dzgishswf/image/upload/v1626111188/portfolio/cookbook_j4xjnf.png",
      largeImageUrl: [
        "https://res.cloudinary.com/dzgishswf/image/upload/v1626111188/portfolio/cookbook_j4xjnf.png",
      ],
      url: "https://app.mycookbook.io/",
    },
    {
      id: 2,
      title: "Zetta Hotel",
      subtitle: "Implementation of Zetta Hotels UI",
      imageUrl:
        "https://res.cloudinary.com/dzgishswf/image/upload/v1626111185/portfolio/zetta-1140_xxbixt.png",
      largeImageUrl: [
        "https://res.cloudinary.com/dzgishswf/image/upload/v1626111185/portfolio/zetta-1140_xxbixt.png",
      ],
      url: "http://zetta-hotels.herokuapp.com/",
    },
    {
      id: 3,
      title: "Prospa",
      subtitle: "Implementation of Prospa UI(Test)",
      imageUrl:
        "https://res.cloudinary.com/dzgishswf/image/upload/v1626111180/portfolio/prospa_p7dvdy.png",
      largeImageUrl: [
        "https://res.cloudinary.com/dzgishswf/image/upload/v1626111180/portfolio/prospa_p7dvdy.png",
      ],
      url: "https://prospa-app.herokuapp.com/",
    },
    {
      id: 4,
      title: "LinkedWallets",
      subtitle: "Implementation of LinkedWallets UI",
      imageUrl:
        "https://res.cloudinary.com/dzgishswf/image/upload/v1626111173/portfolio/linkedwallets_yrpbgn.png",
      largeImageUrl: [
        "https://res.cloudinary.com/dzgishswf/image/upload/v1626111173/portfolio/linkedwallets_yrpbgn.png",
      ],
      url: "https://linked-wallet.herokuapp.com/",
    },
    {
      id: 5,
      title: "Namei",
      subtitle: "Implementation of Namei UI",
      imageUrl:
        "https://res.cloudinary.com/dzgishswf/image/upload/v1626111176/portfolio/namei_kvlltk.png",
      largeImageUrl: [
        "https://res.cloudinary.com/dzgishswf/image/upload/v1626111176/portfolio/namei_kvlltk.png",
      ],
      url: "https://namei1.herokuapp.com/login",
    },
    {
      id: 6,
      title: "KoraPay Book club",
      subtitle: "Implementation for KoraPay UI",
      imageUrl:
        "https://res.cloudinary.com/dzgishswf/image/upload/v1626111193/portfolio/korapay-1750_qx23qb.png",
      largeImageUrl: [
        "https://res.cloudinary.com/dzgishswf/image/upload/v1626111193/portfolio/korapay-1750_qx23qb.png",
      ],
      url: "https://laughing-mccarthy-29c0c0.netlify.app/",
    },
    {
      id: 7,
      title: "Open store",
      subtitle: "Singlepage UI conversion",
      imageUrl:
        "https://res.cloudinary.com/dzgishswf/image/upload/v1626111713/portfolio/Screenshot_2021-07-12_at_18.41.33_rcdggm.png",
      largeImageUrl: [
        "https://res.cloudinary.com/dzgishswf/image/upload/v1626111713/portfolio/Screenshot_2021-07-12_at_18.41.33_rcdggm.png",
      ],
      url: "https://elastic-mahavira-301414.netlify.app/",
    },
    {
      id: 8,
      title: "Game",
      subtitle: "Mobile first Game UI",
      imageUrl:
        "https://res.cloudinary.com/dzgishswf/image/upload/v1626112271/portfolio/Screenshot_2021-07-12_at_18.50.51_ronzs1.png",
      largeImageUrl: [
        "https://res.cloudinary.com/dzgishswf/image/upload/v1626112271/portfolio/Screenshot_2021-07-12_at_18.50.51_ronzs1.png",
      ],
    },
    {
      id: 9,
      title: "Buyerbase",
      subtitle: "Implementation of Buyerbase UI",
      imageUrl:
        "https://res.cloudinary.com/dzgishswf/image/upload/v1634027235/portfolio/buyerbase_register_zbdoa7.png",
      largeImageUrl: [
        "https://res.cloudinary.com/dzgishswf/image/upload/v1634027235/portfolio/buyerbase_register_zbdoa7.png",
      ],
    },
    // {
    //   id: 9,
    //   title: "Packet",
    //   subtitle: "Beautiful packet & product design.",
    //   imageUrl: "/images/portfolio-image-9.jpg",
    //   largeImageUrl: ["/images/portfolio-image-9-lg.jpg"],
    // },
    // {
    //   id: 10,
    //   title: "Pen Holder",
    //   subtitle: "A pen holder with beautiful design.",
    //   imageUrl: "/images/portfolio-image-3.jpg",
    //   largeImageUrl: ["/images/portfolio-image-3-lg.jpg"],
    //   url: "#",
    // },
    // {
    //   id: 11,
    //   title: "Coffee Mug",
    //   subtitle: "Awesome coffee mug design.",
    //   imageUrl: "/images/portfolio-image-4.jpg",
    //   largeImageUrl: [
    //     "/images/portfolio-image-4-lg.jpg",
    //     "/images/portfolio-image-4-lg2.jpg",
    //   ],
    //   url: "https://facebook.com",
    // },
    // {
    //   id: 12,
    //   title: "Tea & Coffee Mug",
    //   subtitle: "Beautiful mug with logo.",
    //   imageUrl: "/images/portfolio-image-2.jpg",
    //   url: "https://pinterest.com",
    // },
    // {
    //   id: 13,
    //   title: "T-shirt Mockup",
    //   subtitle: "A beautiful t-shirt mockup.",
    //   imageUrl: "/images/portfolio-image-1.jpg",
    //   largeImageUrl: ["/images/portfolio-image-1-lg.jpg"],
    //   url: "https://dribbble.com",
    // },
    // {
    //   id: 14,
    //   title: "Mug",
    //   subtitle: "Mug with awesome style",
    //   imageUrl: "/images/portfolio-image-5.jpg",
    //   largeImageUrl: ["/images/portfolio-image-5-lg.jpg"],
    // },
    // {
    //   id: 15,
    //   title: "Pendrive",
    //   subtitle: "Free pendrive mockup design.",
    //   imageUrl: "/images/portfolio-image-6.jpg",
    //   largeImageUrl: ["/images/portfolio-image-6-lg.jpg"],
    // },
    // {
    //   id: 16,
    //   title: "Beautiful Pendrive",
    //   subtitle: "Pendrive with great design & flexible.",
    //   imageUrl: "/images/portfolio-image-7.jpg",
    //   largeImageUrl: ["/images/portfolio-image-7-lg.jpg"],
    //   url: "https://twitter.com",
    // },
    // {
    //   id: 17,
    //   title: "Sticker",
    //   subtitle: "Clip sticker mockup design.",
    //   imageUrl: "/images/portfolio-image-8.jpg",
    //   largeImageUrl: ["/images/portfolio-image-8-lg.jpg"],
    // },
    // {
    //   id: 18,
    //   title: "Packet",
    //   subtitle: "Beautiful packet & product design.",
    //   imageUrl: "/images/portfolio-image-9.jpg",
    //   largeImageUrl: ["/images/portfolio-image-9-lg.jpg"],
    // },
    // {
    //   id: 19,
    //   title: "T-shirt Mockup",
    //   subtitle: "A beautiful t-shirt mockup.",
    //   imageUrl: "/images/portfolio-image-1.jpg",
    //   largeImageUrl: ["/images/portfolio-image-1-lg.jpg"],
    //   url: "https://dribbble.com",
    // },
    // {
    //   id: 20,
    //   title: "Coffee Mug",
    //   subtitle: "Awesome coffee mug design.",
    //   imageUrl: "/images/portfolio-image-4.jpg",
    //   largeImageUrl: [
    //     "/images/portfolio-image-4-lg.jpg",
    //     "/images/portfolio-image-4-lg2.jpg",
    //   ],
    //   url: "https://facebook.com",
    // },
    // {
    //   id: 21,
    //   title: "Tea & Coffee Mug",
    //   subtitle: "Beautiful mug with logo.",
    //   imageUrl: "/images/portfolio-image-2.jpg",
    //   url: "https://pinterest.com",
    // },
    // {
    //   id: 22,
    //   title: "Pen Holder",
    //   subtitle: "A pen holder with beautiful design.",
    //   imageUrl: "/images/portfolio-image-3.jpg",
    //   largeImageUrl: ["/images/portfolio-image-3-lg.jpg"],
    //   url: "#",
    // },
    // {
    //   id: 23,
    //   title: "Mug",
    //   subtitle: "Mug with awesome style",
    //   imageUrl: "/images/portfolio-image-5.jpg",
    //   largeImageUrl: ["/images/portfolio-image-5-lg.jpg"],
    // },
  ],
  experience: {
    workingExperience: [
      {
        id: 1,
        year: "2022(Jan) - Present",
        position: "Software Engineer Javascript",
        company: "Kindred People Ab",
        company_url: "https://www.kindredgroup.com/",
        details:
          "",
      },
      {
        id: 2,
        year: "2021 - 2022(Jan) Contract",
        position: "Senior Frontend Engineer",
        company: "Simplifyy",
        company_url: "https://simplifyy.com/",
        details:
          "",
      },
      {
        id: 3,
        year: "2021 - 2021(Oct)",
        position: "Senior Frontend Engineer",
        company: "ActivEdge Technologies",
        company_url: "https://activedgetechnologies.com/",
        details:
          "Collaborated with a team of 3 other engineers to build a solution for transaction settlement currently running in Eco Bank Ghana which increased the number of transactions settled per day by 81%, completely replaced human manual settlement, and generated over $480,000 in revenue for the company.\nCode reviews to ascertain compliance with standards.\nmentor 3 Junior developers and trainees.\nConduct front-end unit meetings",
      },
      {
        id: 4,
        year: "2019 - 2021",
        position: "Frontend Engineer",
        company: "ActivEdge Technologies",
        company_url: "https://activedgetechnologies.com/",
        details:
          "Collaborated with a team of 2 to develop an account reconciliation solution that reconciles transactions in 2 or more accounts. This solution increased the number of reconciled accounts per day by 56% since our customers used excel macros to reconcile. \nUpgraded the User Management front-end that is responsible for managing roles and permission for users using the reconciliation application. This led to a tangible improvement in user experience as feedback from our users attested to ease in managing roles and permission of their staff.",
      },
      {
        id: 5,
        year: "2016 - Part-time",
        position: "Frontend Engineer(Freelance)",
        company: "Fiverr",
        company_url: "https://www.fiverr.com/unique_designa",
        details:
          "Completed over 32 orders which included developing 10+ mobile and web applications, spa, design conversions, bug fixing, RESTFUL APIs with Node, and tutoring. I received 5-star ratings in all.\nContinually reinforce industry best practices",
      },
      {
        id: 6,
        year: "2020 - Part-time",
        position: "Frontend Engineer",
        company: "Mycookbook",
        company_url: "https://www.mycookbook.io/",
        details:
          "Developed the Frontend for mycookbook which is a solution that provides food lovers with a unique way of saving their favorite recipes in beautifully bound cookbooks thereby empowering food bloggers and professionals to monetise and unlock the value of their recipe collections to a global audience",
      },
      {
        id: 7,
        year: "2018 - 2019",
        position: "Software Engineer",
        company: "Innovantics",
        company_url: "http://innovantics.com/",
        details:
          "Developed a Full-stack, web application for team collaborations and sync, which helped better team communication",
      },
      {
        id: 8,
        year: "2018 - 2018",
        position: "Software Engineer",
        company: "Greenmouse Technologies",
        company_url: "https://www.greenmousetech.com/",
        details:
          "Completed the development and integration of Stripe and other payment APIS on over 10 web applications which drove sales and mitigated manual payment procedures\nOversaw the full Lifecycle of software development for 10 e-commerce web apps with 100% on-time delivery while staying under budget.\nInterfaced with clients to identify business requirements",
      },
      {
        id: 9,
        year: "2017 - 2017",
        position: "Software Engineer Intern",
        company: "Greenmouse Technologies",
        company_url: "https://www.greenmousetech.com/",
        details:
          "Fixed bugs from existing websites and implemented technical enhancements that significantly improved web functionality and speed.\nHelped to achieve a consistent look and visual theme across the website by promoting uniform fonts, formatting, images, and layout.",
      },
    ],
    educationExperience: [
      // {
      //   id: 1,
      //   year: "2018 - 2019",
      //   graduation: "Master of Science",
      //   university: "Abc University",
      //   details:
      //     "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Quas, magni mollitia, aspernatur consequatur accusamus vero eum facere exercitationem velit suscipit ipsam placeat libero. Deleniti exercitationem nostrum quasi. Molestiae, vel porro.",
      // },
      {
        id: 1,
        year: "2014 - 2018",
        graduation: "Bachelor of Science",
        university: "Gregory University",
        details:
          "Course-work - Data Structures and Algorithms, Object-oriented Programming, Introduction to programming, Database Design and Management, Compiler Construction, Software Engineering, Formal Methods, and software development",
      },
      {
        id: 3,
        year: "2007 - 2013",
        graduation: "High School",
        university: "Astec",
        details:
          "Completed with my West African Examination Council (WAEC) and National Examination Council (Neco) Certificates",
      },
    ],
  },
  blogs: [
    {
      id: 1,
      title: "Markdown & Html supported blog.",
      featuredImage: "/images/blog-image-1.jpg",
      filesource: "../../blog/markdown-html-supported-blog.md",
      createDay: "20",
      createMonth: "February",
      createYear: "2020",
    },
    {
      id: 2,
      title: "Installing NodeJS on your device.",
      featuredImage: "/images/blog-image-2.jpg",
      filesource: "../../blog/installing-nodejs-on-your-device.md",
      createDay: "20",
      createMonth: "February",
      createYear: "2020",
    },
    {
      id: 3,
      title: "UI/UX design starter with Adobe XD.",
      featuredImage: "/images/blog-image-3.jpg",
      filesource: "../../blog/uiux-design-starter-with-adobe-xd.md",
      createDay: "20",
      createMonth: "February",
      createYear: "2020",
    },
    {
      id: 4,
      title: "Boost your post for increasing sales.",
      featuredImage: "/images/blog-image-4.jpg",
      filesource: "../../blog/boost-your-post-for-increasing-sales.md",
      createDay: "20",
      createMonth: "February",
      createYear: "2020",
    },
    {
      id: 5,
      title: "Difference between GatsbyJS & NextJS.",
      featuredImage: "/images/blog-image-5.jpg",
      filesource: "../../blog/difference-between-gatsbyjs-and-nextjs.md",
      createDay: "20",
      createMonth: "February",
      createYear: "2020",
    },
    {
      id: 6,
      title: "How to choose javascript framework for project.",
      featuredImage: "/images/blog-image-6.jpg",
      filesource:
        "../../blog/how-to-choose-javascript-framework-for-project.md",
      createDay: "20",
      createMonth: "February",
      createYear: "2020",
    },
    {
      id: 7,
      title: "Web automation with python language.",
      featuredImage: "/images/blog-image-7.jpg",
      filesource: "../../blog/web-automation-with-python-language.md",
      createDay: "20",
      createMonth: "February",
      createYear: "2020",
    },
    {
      id: 8,
      title: "Time to use latest technology for creating a website.",
      featuredImage: "/images/blog-image-8.jpg",
      filesource:
        "../../blog/time-to-use-latest-technology-for-creating-a-website.md",
      createDay: "20",
      createMonth: "February",
      createYear: "2020",
    },
    {
      id: 9,
      title: "Think out of the box.",
      featuredImage: "/images/blog-image-9.jpg",
      filesource: "../../blog/think-out-of-the-box.md",
      createDay: "20",
      createMonth: "February",
      createYear: "2020",
    },
    {
      id: 10,
      title: "Trending designs in 2020.",
      featuredImage: "/images/blog-image-1.jpg",
      filesource: "../../blog/trending-designs-in-2020.md",
      createDay: "20",
      createMonth: "February",
      createYear: "2020",
    },
    {
      id: 11,
      title: "How to get 10k instagram followers?",
      featuredImage: "/images/blog-image-2.jpg",
      filesource: "../../blog/how-to-get-10k-instagram-followers.md",
      createDay: "20",
      createMonth: "February",
      createYear: "2020",
    },
    {
      id: 12,
      title: "What NodeJS can do?",
      featuredImage: "/images/blog-image-3.jpg",
      filesource: "../../blog/what-nodejs-can-do.md",
      createDay: "20",
      createMonth: "February",
      createYear: "2020",
    },
    {
      id: 13,
      title: "Futures of javascript.",
      featuredImage: "/images/blog-image-4.jpg",
      filesource: "../../blog/future-of-javascript.md",
      createDay: "20",
      createMonth: "February",
      createYear: "2020",
    },
    {
      id: 14,
      title: "Popular javascript library in 2020.",
      featuredImage: "/images/blog-image-5.jpg",
      filesource: "../../blog/popular-javascript-library-in-2020.md",
      createDay: "20",
      createMonth: "February",
      createYear: "2020",
    },
    {
      id: 15,
      title: "Promrammers must read books.",
      featuredImage: "/images/blog-image-6.jpg",
      filesource: "../../blog/programmers-must-read-books.md",
      createDay: "20",
      createMonth: "February",
      createYear: "2020",
    },
  ],
  contactInfo: {
    phoneNumbers: ["n/a"],
    emailAddress: ["admin@jaycodes", "jordunjames@gmail.com"],
    address: "Stockholm, Sweden",
  },
};

Mock.onGet("/api/information").reply((config) => {
  const response = database.information;
  return [200, response];
});

Mock.onGet("/api/services").reply((config) => {
  const response = database.services;
  return [200, response];
});

Mock.onGet("/api/reviews").reply((config) => {
  const response = database.reviews;
  return [200, response];
});

Mock.onGet("/api/skills").reply((config) => {
  const response = database.skills;
  return [200, response];
});

Mock.onGet("/api/portfolios").reply((config) => {
  const response = database.portfolios;
  return [200, response];
});

Mock.onGet("/api/experience").reply((config) => {
  const response = database.experience;
  return [200, response];
});

Mock.onGet("/api/blog").reply((config) => {
  const response = database.blogs;
  return [200, response];
});

Mock.onGet("/api/contactinfo").reply((config) => {
  const response = database.contactInfo;
  return [200, response];
});
